import { DropdownItemProps } from "semantic-ui-react";
import { SemanticDDLOption } from "../feature/order/types";
export const termsServiceURL = "https://fasanoassociates.com/terms-of-service/";

export const minDate = "1850-01-01";
export const maxDate = "2099-12-31";
export const maxFileSize = 1e8;

export const SpecialProject = {
    Informal: 1,
    Upgrade: 3
};

export const specialClientIDs = [
    { clientName: "Coventry", clientID: 8 },
    { clientName: "Midwest Fund Management", clientID: 3539 }
];

export const dollarPerPageFeeCheckAmount = 2100;
export const fifteenHundredFeePageCheckAmount = 1500;
export const twothousandAndNinetyNineFeePageCheckAmount = 2099;

export const bulkLESearchClients = {
    coventry: 8,
    itmSample: 28
};

export const businessLineIDs = {
    lifeSettlement: 1,
    structuredSettlement: 2,
    workersComp: 3
};

export const genderOptions: DropdownItemProps[] = [
    { text: "Male", value: "M", key: "M" },
    { text: "Female", value: "F", key: "F" }
];

// this starts at zero because of how Invoiced lists countries
export const CreditCardCountryDdlList: SemanticDDLOption[] = [
    { key: 0, value: "US", text: "United States" },
    { key: 1, value: "AF", text: "Afghanistan" },
    { key: 2, value: "AX", text: "Åland Islands" },
    { key: 3, value: "AL", text: "Albania" },
    { key: 4, value: "DZ", text: "Algeria" },
    { key: 5, value: "AS", text: "American Samoa" },
    { key: 6, value: "AD", text: "Andorra" },
    { key: 7, value: "AO", text: "Angola" },
    { key: 8, value: "AI", text: "Anguilla" },
    { key: 9, value: "AQ", text: "Antarctica" },
    { key: 10, value: "AG", text: "Antigua and Barbuda" },
    { key: 11, value: "AR", text: "Argentina" },
    { key: 12, value: "AM", text: "Armenia" },
    { key: 13, value: "AW", text: "Aruba" },
    { key: 14, value: "AU", text: "Australia" },
    { key: 15, value: "AT", text: "Austria" },
    { key: 16, value: "AZ", text: "Azerbaijan" },
    { key: 17, value: "BS", text: "Bahamas" },
    { key: 18, value: "BH", text: "Bahrain" },
    { key: 19, value: "BD", text: "Bangladesh" },
    { key: 20, value: "BB", text: "Barbados" },
    { key: 21, value: "BY", text: "Belarus" },
    { key: 22, value: "BE", text: "Belgium" },
    { key: 23, value: "BZ", text: "Belize" },
    { key: 24, value: "BJ", text: "Benin" },
    { key: 25, value: "BM", text: "Bermuda" },
    { key: 26, value: "BT", text: "Bhutan" },
    { key: 27, value: "BO", text: "Bolivia, Plurinational State of" },
    { key: 28, value: "BQ", text: "Bonaire, Sint Eustatius and Saba" },
    { key: 29, value: "BA", text: "Bosnia and Herzegovina" },
    { key: 30, value: "BW", text: "Botswana" },
    { key: 31, value: "BV", text: "Bouvet Island" },
    { key: 32, value: "BR", text: "Brazil" },
    { key: 33, value: "IO", text: "British Indian Ocean Territory" },
    { key: 34, value: "BN", text: "Brunei Darussalam" },
    { key: 35, value: "BG", text: "Bulgaria" },
    { key: 36, value: "BF", text: "Burkina Faso" },
    { key: 37, value: "BI", text: "Burundi" },
    { key: 38, value: "KH", text: "Cambodia" },
    { key: 39, value: "CM", text: "Cameroon" },
    { key: 40, value: "CA", text: "Canada" },
    { key: 41, value: "CV", text: "Cape Verde" },
    { key: 42, value: "KY", text: "Cayman Islands" },
    { key: 43, value: "CF", text: "Central African Republic" },
    { key: 44, value: "TD", text: "Chad" },
    { key: 45, value: "CL", text: "Chile" },
    { key: 46, value: "CN", text: "China" },
    { key: 47, value: "CX", text: "Christmas Island" },
    { key: 48, value: "CC", text: "Cocos (Keeling) Islands" },
    { key: 49, value: "CO", text: "Colombia" },
    { key: 50, value: "KM", text: "Comoros" },
    { key: 51, value: "CG", text: "Congo" },
    { key: 52, value: "CD", text: "Congo, Democratic Republic" },
    { key: 53, value: "CK", text: "Cook Islands" },
    { key: 54, value: "CR", text: "Costa Rica" },
    { key: 55, value: "CI", text: "Côte d'Ivoire" },
    { key: 56, value: "HR", text: "Croatia" },
    { key: 57, value: "CU", text: "Cuba" },
    { key: 58, value: "CW", text: "Curaçao" },
    { key: 59, value: "CY", text: "Cyprus" },
    { key: 60, value: "CZ", text: "Czech Republic" },
    { key: 61, value: "DK", text: "Denmark" },
    { key: 62, value: "DJ", text: "Djibouti" },
    { key: 63, value: "DM", text: "Dominica" },
    { key: 64, value: "DO", text: "Dominican Republic" },
    { key: 65, value: "EC", text: "Ecuador" },
    { key: 66, value: "EG", text: "Egypt" },
    { key: 67, value: "SV", text: "El Salvador" },
    { key: 68, value: "GQ", text: "Equatorial Guinea" },
    { key: 69, value: "ER", text: "Eritrea" },
    { key: 70, value: "EE", text: "Estonia" },
    { key: 71, value: "ET", text: "Ethiopia" },
    { key: 72, value: "FK", text: "Falkland Islands (Malvinas)" },
    { key: 73, value: "FO", text: "Faroe Islands" },
    { key: 74, value: "FJ", text: "Fiji" },
    { key: 75, value: "FI", text: "Finland" },
    { key: 76, value: "FR", text: "France" },
    { key: 77, value: "GF", text: "French Guiana" },
    { key: 78, value: "PF", text: "French Polynesia" },
    { key: 79, value: "TF", text: "French Southern Territories" },
    { key: 80, value: "GA", text: "Gabon" },
    { key: 81, value: "GM", text: "Gambia" },
    { key: 82, value: "GE", text: "Georgia" },
    { key: 83, value: "DE", text: "Germany" },
    { key: 84, value: "GH", text: "Ghana" },
    { key: 85, value: "GI", text: "Gibraltar" },
    { key: 86, value: "GR", text: "Greece" },
    { key: 87, value: "GL", text: "Greenland" },
    { key: 88, value: "GD", text: "Grenada" },
    { key: 89, value: "GP", text: "Guadeloupe" },
    { key: 90, value: "GU", text: "Guam" },
    { key: 91, value: "GT", text: "Guatemala" },
    { key: 92, value: "GG", text: "Guernsey" },
    { key: 93, value: "GN", text: "Guinea" },
    { key: 94, value: "GW", text: "Guinea-Bissau" },
    { key: 95, value: "GY", text: "Guyana" },
    { key: 96, value: "HT", text: "Haiti" },
    { key: 97, value: "HM", text: "Heard Island and McDonald Islands" },
    { key: 98, value: "VA", text: "Holy See (Vatican City State)" },
    { key: 99, value: "HN", text: "Honduras" },
    { key: 100, value: "HK", text: "Hong Kong" },
    { key: 101, value: "HU", text: "Hungary" },
    { key: 102, value: "IS", text: "Iceland" },
    { key: 103, value: "IN", text: "India" },
    { key: 104, value: "ID", text: "Indonesia" },
    { key: 105, value: "IR", text: "Iran, Islamic Republic of" },
    { key: 106, value: "IQ", text: "Iraq" },
    { key: 107, value: "IE", text: "Ireland" },
    { key: 108, value: "IM", text: "Isle of Man" },
    { key: 109, value: "IL", text: "Israel" },
    { key: 110, value: "IT", text: "Italy" },
    { key: 111, value: "JM", text: "Jamaica" },
    { key: 112, value: "JP", text: "Japan" },
    { key: 113, value: "JE", text: "Jersey" },
    { key: 114, value: "JO", text: "Jordan" },
    { key: 115, value: "KZ", text: "Kazakhstan" },
    { key: 116, value: "KE", text: "Kenya" },
    { key: 117, value: "KI", text: "Kiribati" },
    { key: 118, value: "KP", text: "Korea, Democratic People's Republic" },
    { key: 119, value: "KR", text: "Korea, Republic of" },
    { key: 120, value: "KW", text: "Kuwait" },
    { key: 121, value: "KG", text: "Kyrgyzstan" },
    { key: 122, value: "LA", text: "Lao People's Democratic Republic" },
    { key: 123, value: "LV", text: "Latvia" },
    { key: 124, value: "LB", text: "Lebanon" },
    { key: 125, value: "LS", text: "Lesotho" },
    { key: 126, value: "LR", text: "Liberia" },
    { key: 127, value: "LY", text: "Libya" },
    { key: 128, value: "LI", text: "Liechtenstein" },
    { key: 129, value: "LT", text: "Lithuania" },
    { key: 130, value: "LU", text: "Luxembourg" },
    { key: 131, value: "MO", text: "Macao" },
    { key: 132, value: "MK", text: "Macedonia, former Yugoslav Republic of" },
    { key: 133, value: "MG", text: "Madagascar" },
    { key: 134, value: "MW", text: "Malawi" },
    { key: 135, value: "MY", text: "Malaysia" },
    { key: 136, value: "MV", text: "Maldives" },
    { key: 137, value: "ML", text: "Mali" },
    { key: 138, value: "MT", text: "Malta" },
    { key: 139, value: "MH", text: "Marshall Islands" },
    { key: 140, value: "MQ", text: "Martinique" },
    { key: 141, value: "MR", text: "Mauritania" },
    { key: 142, value: "MU", text: "Mauritius" },
    { key: 143, value: "YT", text: "Mayotte" },
    { key: 144, value: "MX", text: "Mexico" },
    { key: 145, value: "FM", text: "Micronesia, Federated States of" },
    { key: 146, value: "MD", text: "Moldova, Republic of" },
    { key: 147, value: "MC", text: "Monaco" },
    { key: 148, value: "MN", text: "Mongolia" },
    { key: 149, value: "ME", text: "Montenegro" },
    { key: 150, value: "MS", text: "Montserrat" },
    { key: 151, value: "MA", text: "Morocco" },
    { key: 152, value: "MZ", text: "Mozambique" },
    { key: 153, value: "MM", text: "Myanmar" },
    { key: 154, value: "NA", text: "Namibia" },
    { key: 155, value: "NR", text: "Nauru" },
    { key: 156, value: "NP", text: "Nepal" },
    { key: 157, value: "NL", text: "Netherlands" },
    { key: 158, value: "NC", text: "New Caledonia" },
    { key: 159, value: "NZ", text: "New Zealand" },
    { key: 160, value: "NI", text: "Nicaragua" },
    { key: 161, value: "NE", text: "Niger" },
    { key: 162, value: "NG", text: "Nigeria" },
    { key: 163, value: "NU", text: "Niue" },
    { key: 164, value: "NF", text: "Norfolk Island" },
    { key: 165, value: "MP", text: "Northern Mariana Islands" },
    { key: 166, value: "NO", text: "Norway" },
    { key: 167, value: "OM", text: "Oman" },
    { key: 168, value: "PK", text: "Pakistan" },
    { key: 169, value: "PW", text: "Palau" },
    { key: 170, value: "PS", text: "Palestinian Territory, Occupied" },
    { key: 171, value: "PA", text: "Panama" },
    { key: 172, value: "PG", text: "Papua New Guinea" },
    { key: 173, value: "PY", text: "Paraguay" },
    { key: 174, value: "PE", text: "Peru" },
    { key: 175, value: "PH", text: "Philippines" },
    { key: 176, value: "PN", text: "Pitcairn" },
    { key: 177, value: "PL", text: "Poland" },
    { key: 178, value: "PT", text: "Portugal" },
    { key: 179, value: "PR", text: "Puerto Rico" },
    { key: 180, value: "QA", text: "Qatar" },
    { key: 181, value: "RE", text: "Réunion" },
    { key: 182, value: "RO", text: "Romania" },
    { key: 183, value: "RU", text: "Russian Federation" },
    { key: 184, value: "RW", text: "Rwanda" },
    { key: 185, value: "BL", text: "Saint Barthélemy" },
    { key: 186, value: "SH", text: "Saint Helena, Ascension and Tn da Cunha" },
    { key: 187, value: "KN", text: "Saint Kitts and Nevis" },
    { key: 188, value: "LC", text: "Saint Lucia" },
    { key: 189, value: "MF", text: "Saint Martin (French part)" },
    { key: 190, value: "PM", text: "Saint Pierre and Miquelon" },
    { key: 191, value: "VC", text: "Saint Vincent and the Grenadines" },
    { key: 192, value: "WS", text: "Samoa" },
    { key: 193, value: "SM", text: "San Marino" },
    { key: 194, value: "ST", text: "Sao Tome and Principe" },
    { key: 195, value: "SA", text: "Saudi Arabia" },
    { key: 196, value: "SN", text: "Senegal" },
    { key: 197, value: "RS", text: "Serbia" },
    { key: 198, value: "SC", text: "Seychelles" },
    { key: 199, value: "SL", text: "Sierra Leone" },
    { key: 200, value: "SG", text: "Singapore" },
    { key: 201, value: "SX", text: "Sint Maarten (Dutch part)" },
    { key: 202, value: "SK", text: "Slovakia" },
    { key: 203, value: "SI", text: "Slovenia" },
    { key: 204, value: "SB", text: "Solomon Islands" },
    { key: 205, value: "SO", text: "Somalia" },
    { key: 206, value: "ZA", text: "South Africa" },
    { key: 207, value: "GS", text: "South Georgia and the S. Sandwich Isls" },
    { key: 208, value: "SS", text: "South Sudan" },
    { key: 209, value: "ES", text: "Spain" },
    { key: 210, value: "LK", text: "Sri Lanka" },
    { key: 211, value: "SD", text: "Sudan" },
    { key: 212, value: "SR", text: "Suriname" },
    { key: 213, value: "SJ", text: "Svalbard and Jan Mayen" },
    { key: 214, value: "SZ", text: "Swaziland" },
    { key: 215, value: "SE", text: "Sweden" },
    { key: 216, value: "CH", text: "Switzerland" },
    { key: 217, value: "SY", text: "Syrian Arab Republic" },
    { key: 218, value: "TW", text: "Taiwan, Province of China" },
    { key: 219, value: "TJ", text: "Tajikistan" },
    { key: 220, value: "TZ", text: "Tanzania, United Republic of" },
    { key: 221, value: "TH", text: "Thailand" },
    { key: 222, value: "TL", text: "Timor-Leste" },
    { key: 223, value: "TG", text: "Togo" },
    { key: 224, value: "TK", text: "Tokelau" },
    { key: 225, value: "TO", text: "Tonga" },
    { key: 226, value: "TT", text: "Trinidad and Tobago" },
    { key: 227, value: "TN", text: "Tunisia" },
    { key: 228, value: "TR", text: "Turkey" },
    { key: 229, value: "TM", text: "Turkmenistan" },
    { key: 230, value: "TC", text: "Turks and Caicos Islands" },
    { key: 231, value: "TV", text: "Tuvalu" },
    { key: 232, value: "UG", text: "Uganda" },
    { key: 233, value: "UA", text: "Ukraine" },
    { key: 234, value: "AE", text: "United Arab Emirates" },
    { key: 235, value: "GB", text: "United Kingdom" },
    { key: 236, value: "UM", text: "United States Minor Outlying Islands" },
    { key: 237, value: "UY", text: "Uruguay" },
    { key: 238, value: "UZ", text: "Uzbekistan" },
    { key: 239, value: "VU", text: "Vanuatu" },
    { key: 240, value: "VE", text: "Venezuela, Bolivarian Republic of" },
    { key: 241, value: "VN", text: "Viet Nam" },
    { key: 242, value: "VG", text: "Virgin Islands, British" },
    { key: 243, value: "VI", text: "Virgin Islands, U.S." },
    { key: 244, value: "WF", text: "Wallis and Futuna" },
    { key: 245, value: "EH", text: "Western Sahara" },
    { key: 246, value: "YE", text: "Yemen" },
    { key: 247, value: "ZM", text: "Zambia" },
    { key: 248, value: "ZW", text: "Zimbabwe" }
];

export const CountryDdlList: SemanticDDLOption[] = CreditCardCountryDdlList.map((item) => {
    return {
        key: item.key,
        value: item.key,
        text: item.text
    };
});

export const StateDdlList: SemanticDDLOption[] = [
    { key: 1, value: "AL", text: "Alabama" },
    { key: 2, value: "AK", text: "Alaska" },
    { key: 3, value: "AZ", text: "Arizona" },
    { key: 4, value: "AR", text: "Arkansas" },
    { key: 5, value: "CA", text: "California" },
    { key: 6, value: "CO", text: "Colorado" },
    { key: 7, value: "CT", text: "Connecticut" },
    { key: 8, value: "DE", text: "Delaware" },
    { key: 9, value: "FL", text: "Florida" },
    { key: 10, value: "GA", text: "Georgia" },
    { key: 11, value: "HI", text: "Hawaii" },
    { key: 12, value: "ID", text: "Idaho" },
    { key: 13, value: "IL", text: "Illinois" },
    { key: 14, value: "IN", text: "Indiana" },
    { key: 15, value: "IA", text: "Iowa" },
    { key: 16, value: "KS", text: "Kansas" },
    { key: 17, value: "KY", text: "Kentucky" },
    { key: 18, value: "LA", text: "Louisiana" },
    { key: 19, value: "ME", text: "Maine" },
    { key: 20, value: "MD", text: "Maryland" },
    { key: 21, value: "MA", text: "Massachusetts" },
    { key: 22, value: "MI", text: "Michigan" },
    { key: 23, value: "MN", text: "Minnesota" },
    { key: 24, value: "MS", text: "Mississippi" },
    { key: 25, value: "MO", text: "Missouri" },
    { key: 26, value: "MT", text: "Montana" },
    { key: 27, value: "NE", text: "Nebraska" },
    { key: 28, value: "NV", text: "Nevada" },
    { key: 29, value: "NH", text: "New Hampshire" },
    { key: 30, value: "NJ", text: "New Jersey" },
    { key: 31, value: "NM", text: "New Mexico" },
    { key: 32, value: "NY", text: "New York" },
    { key: 33, value: "NC", text: "North Carolina" },
    { key: 34, value: "ND", text: "North Dakota" },
    { key: 35, value: "OH", text: "Ohio" },
    { key: 36, value: "OK", text: "Oklahoma" },
    { key: 37, value: "OR", text: "Oregon" },
    { key: 38, value: "PA", text: "Pennsylvania" },
    { key: 39, value: "RI", text: "Rhode Island" },
    { key: 40, value: "SC", text: "South Carolina" },
    { key: 41, value: "SD", text: "South Dakota" },
    { key: 42, value: "TN", text: "Tennessee" },
    { key: 43, value: "TX", text: "Texas" },
    { key: 44, value: "UT", text: "Utah" },
    { key: 45, value: "VT", text: "Vermont" },
    { key: 46, value: "VA", text: "Virginia" },
    { key: 47, value: "WA", text: "Washington" },
    { key: 48, value: "WV", text: "West Virginia" },
    { key: 49, value: "WI", text: "Wisconsin" },
    { key: 50, value: "WY", text: "Wyoming" },
    { key: 51, value: "DC", text: "District Of Columbia" },
    { key: 52, value: "AS", text: "American Samoa" },
    { key: 53, value: "GU", text: "Guam" },
    { key: 54, value: "MP", text: "Northern Mariana Islands" },
    { key: 55, value: "PR", text: "Puerto Rico" },
    { key: 56, value: "VI", text: "U.S. Virgin Islands" }
];

export const ProvinceDdlList: SemanticDDLOption[] = [
    { key: 57, value: "AB", text: "Alberta" },
    { key: 58, value: "BC", text: "British Columbia" },
    { key: 59, value: "MB", text: "Manitoba" },
    { key: 60, value: "NB", text: "New Brunswick" },
    { key: 61, value: "NL", text: "Newfoundland and Labrador" },
    { key: 62, value: "NS", text: "Nova Scotia" },
    { key: 63, value: "ON", text: "Ontario" },
    { key: 64, value: "PE", text: "Prince Edward Island" },
    { key: 65, value: "QC", text: "Quebec" },
    { key: 66, value: "SK", text: "Saskatchewan" }
];

export const MonthDdlList: SemanticDDLOption[] = [
    { key: 1, value: "1", text: "01" },
    { key: 2, value: "2", text: "02" },
    { key: 3, value: "3", text: "03" },
    { key: 4, value: "4", text: "04" },
    { key: 5, value: "5", text: "05" },
    { key: 6, value: "6", text: "06" },
    { key: 7, value: "7", text: "07" },
    { key: 8, value: "8", text: "08" },
    { key: 9, value: "9", text: "09" },
    { key: 10, value: "10", text: "10" },
    { key: 11, value: "11", text: "11" },
    { key: 12, value: "12", text: "12" }
];

export const YearDdlList = (): SemanticDDLOption[] => {
    const now: Date = new Date();
    const year: number = now.getFullYear();
    const years: SemanticDDLOption[] = [];
    const totalYears = 8;

    for (let i = 0; i < totalYears; i += 1) {
        const newYear = year + i;
        years.push({
            key: i,
            value: newYear.toString(),
            text: newYear.toString()
        });
    }

    return years;
};
