import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./css/kendo.scss";
import "./css/styles.scss";

import { Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense, lazy } from "react";

import AuthError from "./components/AuthError";
import FullScreenLoader from "./components/FullScreenLoader";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import { useAuth0 } from "@auth0/auth0-react";

const AdhocCaseSearch = lazy(() => import("./feature/adhoc-case-search/AdhocCaseSearch"));
const AdhocOrder = lazy(() => import("./feature/adhoc-order/AdhocOrder"));
const AdhocDashboard = lazy(() => import("./feature/adhoc-dashboard/AdhocDashboard"));
const BulkLESearch = lazy(() => import("./feature/bulk-le-search/BulkLESearch"));
const CaseSearch = lazy(() => import("./feature/case-search/CaseSearch"));
const Calculator = lazy(() => import("./feature/le-calculator/LeCalculator"));
const Dashboard = lazy(() => import("./feature/dashboard/Dashboard"));
const DownloadReport = lazy(() => import("./feature/download-report/DownloadReport"));
const Inbox = lazy(() => import("./feature/inbox/Inbox"));
const Order = lazy(() => import("./feature/order/Order"));
const UserManagement = lazy(() => import("./feature/client-management/UserManagement"));

const App: React.FC = () => {
    const { error, isLoading } = useAuth0();

    if (isLoading) {
        return <FullScreenLoader />;
    }

    if (error) {
        return <AuthError error={error} />;
    }

    return (
        <Suspense fallback={<FullScreenLoader />}>
            <Routes>
                <Route path="/" element={<PrivateRoute component={Layout} />}>
                    <Route path="/case-search-submission" element={<AdhocCaseSearch />} />
                    <Route path="/order-submission" element={<AdhocOrder />} />
                    <Route path="/dashboard-submission" element={<AdhocDashboard />} />
                    <Route path="/bulk-le-search" element={<BulkLESearch />} />
                    <Route path="/case-search" element={<CaseSearch />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/download-report/:submitID" element={<DownloadReport />} />
                    <Route path="/inbox" element={<Inbox />} />
                    <Route path="/le-calculator" element={<PrivateRoute component={Calculator} AllowIfCanViewLECalculator />} />
                    <Route path="/order" element={<Order />} />
                    <Route path="/user-management" element={<PrivateRoute component={UserManagement} AllowIfAdmin />} />

                    <Route path="/" element={<Navigate to="/dashboard" replace />} />
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default App;
