/* eslint-disable @typescript-eslint/no-explicit-any */

import { Header, Icon } from "semantic-ui-react";
import React, { useContext } from "react";

import { AppContext } from "./AppContextProvider";
import { useNavigate } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

interface PrivateRouteProps {
    component: React.ComponentType<any>;
    AllowIfAdmin?: boolean;
    AllowIfCanViewLECalculator?: boolean;
    [key: string]: any;
}

const UserPermissionCheck = () => <Header color="grey" icon={<Icon name="cog" loading />} content="Checking user permissions" />;

const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
    const { component, AllowIfAdmin, AllowIfCanViewLECalculator, ...rest } = props;

    const navigate = useNavigate();
    const { permissions } = useContext(AppContext);
    if (AllowIfAdmin) {
        if (!permissions) {
            return <UserPermissionCheck />;
        }
        if (!permissions.Admin) {
            navigate("/");
            return null;
        }
    }
    if (AllowIfCanViewLECalculator) {
        if (!permissions) {
            return <UserPermissionCheck />;
        }
        if (!permissions.CanViewLECalculator) {
            navigate("/");
            return null;
        }
    }

    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => <p>Redirecting to authentication</p>
    });

    return <Component {...rest} />;
};

export default PrivateRoute;
