import EmailService from "@itm21st/email-service";
import axios from "axios";
import config from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

interface EmailServiceOutput {
    getEmailService: () => Promise<EmailService>;
}

const UseEmailService = (): EmailServiceOutput => {
    const { getAccessTokenSilently } = useAuth0();

    const getEmailService = useCallback(async (): Promise<EmailService> => {
        const accessToken = await getAccessTokenSilently();
        const { emailApiUrl } = config;
        return new EmailService({
            axiosInstance: axios.create({
                baseURL: emailApiUrl,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
        });
    }, [getAccessTokenSilently]);

    return { getEmailService };
};

export default UseEmailService;
