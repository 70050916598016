/* eslint-disable @typescript-eslint/no-var-requires */
const env = process.env.REACT_APP_ENVIRONMENT || "dev";

const envConfig = require(`./config.${env}`);
const rootConfig = require("./config.json");

const config: {
    auth0: {
        audience: string;
        clientId: string;
        domain: string;
    };
    authApiUrl: string;
    calculationApiUrl: string;
    cloudApiBaseUrl: string;
    connectWebUrl: string;
    databrokerApiUrl: string;
    fileApiUrl: string;
    invoicedPublishableKey: string;
    invoicedScriptUrl: string;
    emailApiUrl: string;
    noReplyEmail: string;
    paymentApiUrl: string;
    supportEmailAddress: string;
    supportPhoneNumber: string;
} = { ...rootConfig, ...envConfig };

export default config;
