import { ClientUser, UserPermissions } from "../types";
import React, { createContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import useSproc from "../hooks/useSproc";
import { useState } from "react";

interface AppContextProps {
    unreadMessageCount?: number;
    getUnreadMessageCount: () => Promise<number | undefined>;
    clientUser?: ClientUser;
    permissions?: UserPermissions;
    setClientUser: React.Dispatch<React.SetStateAction<ClientUser | undefined>>;
    clientUserOptions?: ClientUser[];
}

export const AppContext = createContext<AppContextProps>({
    getUnreadMessageCount: () => {
        throw new Error("not implemented");
    },
    setClientUser: () => {
        throw new Error("not implemented");
    }
});

const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [clientUser, setClientUser] = useState<ClientUser>();
    const [clientUserOptions, setClientUserOptions] = useState<ClientUser[]>();
    const [unreadMessageCount, setUnreadMessageCount] = useState<number>();
    const [permissions, setPermissions] = useState<UserPermissions>();

    const { isAuthenticated } = useAuth0();
    const sproc = useSproc();

    const getUnreadMessageCount = useCallback(async (): Promise<number | undefined> => {
        setUnreadMessageCount(undefined);
        if (!clientUser?.ClientID) {
            return undefined;
        }
        try {
            const [{ UnreadMessageCount }] = await sproc<{
                UnreadMessageCount: number;
            }>("GetClientUnreadMessageCount", {
                ClientID: clientUser.ClientID
            });
            setUnreadMessageCount(UnreadMessageCount);
            return UnreadMessageCount;
        } catch (error) {
            console.error(error);
        }
    }, [clientUser?.ClientID, sproc]);

    useEffect(() => {
        getUnreadMessageCount();
    }, [getUnreadMessageCount]);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        (async () => {
            try {
                const [clientData, [userPermissions]] = await Promise.all([
                    sproc<ClientUser>("GetClientUserInfo"),
                    sproc<UserPermissions>("GetFASTUserPermissions")
                ]);
                setClientUserOptions(clientData);
                setClientUser(clientData[0]);
                setPermissions(userPermissions);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [isAuthenticated, sproc]);

    return (
        <AppContext.Provider
            value={{ unreadMessageCount, getUnreadMessageCount, clientUser, clientUserOptions, setClientUser, permissions }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
