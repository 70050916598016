import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";

import config from "../config";
import { useNavigate } from "react-router-dom";

type Auth0ProviderWithHistoryProps = PropsWithChildren<unknown>;
const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({ children }) => {
    const navigate = useNavigate();
    const onRedirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider {...config.auth0} redirectUri={window.location.origin} onRedirectCallback={onRedirectCallback}>
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
