import { Dropdown, Icon, Image, Label, Menu } from "semantic-ui-react";
import { bulkLESearchClients, termsServiceURL } from "../services/Constants";

import { AppContext } from "./AppContextProvider";
import { Link } from "react-router-dom";
import React from "react";
import logo from "../img/fasano-primary-logo.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";

const ADHOCLOBID = 8;
const Navbar: React.FC = () => {
    const { clientUser, permissions } = useContext(AppContext);
    return (
        <Menu fluid>
            <Menu.Menu position="left">
                <Menu.Item
                    as={Link}
                    to="/"
                    header
                    content={<Image src={logo} style={{ height: "4rem" }} />}
                    position="left"
                    className="py-0"
                />

                {clientUser?.BusinessLineID !== ADHOCLOBID && (
                    <Menu.Item as={Link} to="/case-search">
                        <Icon name="search" className="fasano-blue" />
                        Case Search
                    </Menu.Item>
                )}
                {clientUser?.BusinessLineID === ADHOCLOBID && (
                    <Menu.Item as={Link} to="/case-search-submission">
                        <Icon name="search" className="fasano-blue" />
                        Case Search
                    </Menu.Item>
                )}
                {clientUser?.BusinessLineID !== ADHOCLOBID && (
                    <Menu.Item as={Link} to="/order">
                        <Icon name="certificate" className="fasano-blue" />
                        Order Life Expectancy
                    </Menu.Item>
                )}
                {clientUser?.BusinessLineID === ADHOCLOBID && (
                    <Menu.Item as={Link} to="/order-submission">
                        <Icon name="certificate" className="fasano-blue" />
                        Submit Order
                    </Menu.Item>
                )}

                {!!clientUser &&
                    (clientUser.ClientID === bulkLESearchClients.coventry || clientUser.ClientID === bulkLESearchClients.itmSample) && (
                        <Menu.Item as={Link} to="/bulk-le-search">
                            <Icon name="search plus" className="fasano-blue" />
                            Bulk LE Search
                        </Menu.Item>
                    )}
                {permissions?.CanViewLECalculator && (
                    <Dropdown item text="Tools">
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/le-calculator">
                                <Icon name="calculator" className="fasano-blue" />
                                LE Calculator
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </Menu.Menu>
            <Menu.Menu position="right">
                <DashboardMenuItem />
                <Menu.Item href={termsServiceURL} target="_blank">
                    <Icon name="file alternate outline" className="fasano-blue" />
                    Terms of Service
                </Menu.Item>
                <ClientDropdown />
                <UserDropdown />
            </Menu.Menu>
        </Menu>
    );
};

export default Navbar;

const DashboardMenuItem: React.FC = () => {
    const { clientUser, unreadMessageCount } = useContext(AppContext);
    if (clientUser?.BusinessLineID !== ADHOCLOBID) {
        return (
            <Menu.Item as={Link} to="/dashboard">
                <Icon name="mail" className="fasano-blue" />
                Dashboard
                <Label circular className="fasano-blue" size="small">
                    {unreadMessageCount ?? "?"}
                </Label>
            </Menu.Item>
        );
    } else
        return (
            <Menu.Item as={Link} to="/dashboard-submission">
                <Icon name="mail" className="fasano-blue" />
                Dashboard
                <Label circular className="fasano-blue" size="small">
                    {unreadMessageCount ?? "?"}
                </Label>
            </Menu.Item>
        );
};

// make this show only if clientOptions > 1
const ClientDropdown: React.FC = () => {
    const { clientUserOptions, clientUser, setClientUser } = useContext(AppContext);
    if (!clientUser || !clientUserOptions || clientUserOptions.length < 2) {
        return null;
    }

    const options = clientUserOptions.map((client) => ({
        key: client.ClientID,
        value: client.ClientID,
        text: client.ClientName
    }));

    return (
        <Dropdown
            item
            value={clientUser?.ClientID}
            options={options || []}
            onChange={(e, { value }) => {
                const foundClient = clientUserOptions.find((clientOpt) => clientOpt.ClientID === +(value as number));
                foundClient && setClientUser((prevState) => ({ ...prevState, ...foundClient }));
            }}
        />
    );
};

const UserDropdown: React.FC = () => {
    const { permissions } = useContext(AppContext);
    const { logout, user } = useAuth0();
    return (
        <Dropdown item text={user?.nickname}>
            <Dropdown.Menu>
                <Dropdown.Item content="Order LE" icon="user md" as={Link} to="/order" />
                <Dropdown.Divider />
                {permissions?.Admin && (
                    <Dropdown.Item as={Link} to="user-management">
                        <Icon name="users" />
                        User Management
                    </Dropdown.Item>
                )}
                <Dropdown.Item content="Log Out" icon="log out" as={Link} to="/login" onClick={() => logout()} />
            </Dropdown.Menu>
        </Dropdown>
    );
};
