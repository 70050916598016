import { Dimmer, Loader } from "semantic-ui-react";
import React, { ReactElement } from "react";

interface FullScreenLoaderProps {
    message?: string;
}

/** Appears centered on the screen whereas the simple loader is only centered on a line (not vertically) */
const FullScreenLoader = (props: FullScreenLoaderProps): ReactElement<typeof Dimmer> => {
    const { message } = props;
    return (
        <div className="fullscreen">
            <Dimmer active inverted>
                <Loader>{message || "loading..."}</Loader>
            </Dimmer>
        </div>
    );
};

export default FullScreenLoader;
