import { DropdownItemProps } from "semantic-ui-react";
import { SearchResult } from "../feature/case-search/CaseSearch";
import _ from "lodash";
import moment from "moment";
import momentTz from "moment-timezone";

const dateRegExp = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

export const delay = async (seconds: number): Promise<void> => {
    return new Promise<void>((resolve) => {
        setTimeout(resolve, seconds * 1000);
    });
};

function processDateString(dateString: string, retainTime?: boolean): Date {
    if (retainTime) {
        const tempDateTimeString: string = dateString.replace(/Z/, ""); // remove erroneous Z timezone, thereby setting timezone to local time
        return new Date(tempDateTimeString);
    } else {
        const tempDateOnlyString: string = dateString.replace(/T.*/, "");
        return new Date(tempDateOnlyString + "T00:00:00");
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function processObjectWithDates(item: any, retainTime?: boolean, format?: string) {
    for (const key of Object.keys(item)) {
        if (dateRegExp.test(item[key])) {
            const dateString: string = item[key];
            let tempDate: Date | string = processDateString(dateString, retainTime);
            if (format) {
                tempDate = moment(tempDate).format(format);
            }
            item[key] = tempDate;
        }
    }
    return item;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function processDates(data: Date, retainTime?: boolean, format?: string): any {
    try {
        if (_.isArray(data)) {
            const tempData = data.map((item) => {
                return { ...item, CreatedDate: new Date(item.CreatedDate) };
            });
            return tempData.map((item) => processObjectWithDates(item, retainTime, format));
        } else if (_.isDate(data)) {
            // format single date
            if (format) {
                return moment(data).format(format);
            } else {
                console.error("Error: must provide date format");
            }
        } else if (!_.isArray(data) && _.isObject(data)) {
            return processObjectWithDates(data, retainTime, format);
        }
    } catch (err) {
        console.error(err);
    }
}

export const formatCurrency = (num: number | string | undefined): string | undefined => {
    if (!num && num !== 0) {
        return ``;
    }
    return `$${num.toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
};

export function formatDate(date: string, format?: string): string {
    return moment(date).format(format || "MM/DD/YYYY");
}

type DropdownItem = {
    [key: string]: string | number | boolean;
};

/** Formats array for semantic ui react dropdown.
 * Note input array must be a type, not an interface
 */
export const formatDropdownItems = <T extends DropdownItem>(
    input: T[],
    options: { key: keyof T; value: keyof T; text: keyof T }
): DropdownItemProps[] => {
    const { key, value, text } = options;
    return input.map((item) => ({ key: item[key], value: item[value], text: item[text] }));
};

export function fixFilename(filename: string): string {
    const newFilename: string = filename
        .replace(/[^A-Za-z0-9 -_.]/gi, " ") // whitelist chars
        .replace(/&amp;/gi, "") // kendo converts & to &amp; - remove
        .replace(/[|&;$%@"'#<>[\]{},!+]/g, " ") // remove these chars
        .replace(/ +/g, " ") // trim extra spaces
        .replace(/ .pdf$/, ".pdf"); // remove space before extension
    return newFilename;
}

export function decodeSpecialCharacters(input: string): string {
    const tempText = document.createElement("textarea");
    tempText.innerHTML = input;
    return tempText.value;
}

export const getISODateTimeStamp = (date?: string): string => {
    return momentTz(date || new Date())
        .tz("America/Chicago")
        .format("YYYY-MM-DD HH:mm:ss.SSS");
};

export function toIsoDateString(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

export const canUpgrade = (searchResult: SearchResult): boolean => {
    const { SpecialProjectName, PublishedDate, UpgradeCaseID, CaseStatus } = searchResult;
    return SpecialProjectName === "Informal" && !!PublishedDate && !UpgradeCaseID && CaseStatus.toLowerCase() === "completed";
};
