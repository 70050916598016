import { SprocParameters } from "@itm21st/databroker";
import { useCallback } from "react";
import useDatabroker from "./useDatabroker";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SprocFunction = <T = any>(sprocName: string, sprocParams?: SprocParameters) => Promise<T[]>;

const useSproc = (): SprocFunction => {
    const databroker = useDatabroker();
    const sproc = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async <T = any>(sprocName: string, sprocParams?: SprocParameters): Promise<T[]> => {
            const result = await databroker.sproc<T>({
                objectName: sprocName,
                parameters: sprocParams
            });
            const { data, error } = result;
            if (error) {
                console.error(error);
                throw new Error(error);
            }
            return data as T[];
        },
        [databroker]
    );

    return sproc;
};

export default useSproc;
