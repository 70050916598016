(function (n, i, v, r, s, c, x, z) {
    x = window.AwsRumClient = { q: [], n: n, i: i, v: v, r: r, c: c };
    window[n] = function (c, p) {
        x.q.push({ c: c, p: p });
    };
    z = document.createElement("script");
    z.async = true;
    z.src = s;
    document.head.insertBefore(z, document.getElementsByTagName("script")[0]);
})("cwr", "c6d06bab-75d7-43f4-81da-0f9da0941d34", "1.0.0", "us-east-1", "https://client.rum.us-east-1.amazonaws.com/1.0.2/cwr.js", {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::020635911628:role/RUM-Monitor-us-east-1-020635911628-5746026062461-Unauth",
    identityPoolId: "us-east-1:bdad07a0-e75c-41ce-a0b8-0c7c5aa43ded",
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: true
});
